import React from 'react';
import { Recaptcha } from './components/Recaptcha';
import LinkBox from './components/LinkBox';
import 'tailwindcss/tailwind.css';

function App() {
  return (
    <div className="bg-black min-h-screen">
      <LinkBox />

      {/* <Recaptcha /> */}
    </div>
  );
}

export default App;
