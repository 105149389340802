export const LinkBox = () => {
  const REDIRECT_LINK = process.env.REACT_APP_DISCORD_INVITE_URL
    ? process.env.REACT_APP_DISCORD_INVITE_URL
    : 'https://discord.gg/TbXjW3pvkv';

  return (
    <div className="pt-32">
      <div className="bg-slate-700 max-w-md rounded-lg mx-auto ">
        <div className="flex items-center p-4 mx-auto container">
          <div>
            <img className="max-h-12" src="https://avatars.githubusercontent.com/u/98690148?s=96&v=4" alt="" />
          </div>
          <div className="text-white text-3xl ml-2">Starbank</div>
        </div>
        <div className="bg-slate-800 text-white rounded-lg px-4 py-8">
          <p className="text-2xl"> Starbank Discord Invite</p>
          {/* <p className="text-lg">Are you human? Prove it.</p> */}
          <button
            type="button"
            className="my-4 inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <a href={REDIRECT_LINK}>JOIN DISCORD </a>
          </button>
          {/* <p className="text-sm">
            This is a one-time use invite to join the Balancer Discord server. Further verification steps at server
            needed!
          </p> */}
          {/* Recaptcha doesn't need yet */}
          {/* <Recaptcha /> */}
        </div>
      </div>
    </div>
  );
};

export default LinkBox;
